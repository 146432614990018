import { useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import React, { useState } from 'react'
import LoadingPage from '../loading/LoadingPage'
import AddTenantPage from './AddTenantPage'
import useFetchAddTenantData from './useFetchAddTenantData'
import getTenantType from './getTenantType'

const SignUpMutation = gql`
    mutation signUp(
        $input: SignUpInput!
    ) {
        signUp(
            input: $input
        ) {
            signUpResult{
                email
                password
                tenantGuid
            }
        }
    }
`

const AddTenantPageWithState = () => {
    const [newTenantInfo, setNewTenantInfo] = useState(null)  
    const { isFetching, products } = useFetchAddTenantData()
    const { tenantTypes } = getTenantType()
    const [signUp, { loading: isSubmitting }] = useMutation(SignUpMutation)
    if (isFetching) {
        return <LoadingPage />
    }

    return (
        <AddTenantPage
            products={products}
            tenantTypes={tenantTypes}
            onSubmit={async (input) => {
                const variables = { input }
                const { data } = await signUp({ variables })
                setNewTenantInfo(data.signUp.signUpResult)
            }}
            isSubmitting={isSubmitting}
            newTenantInfo={newTenantInfo}
        />
    )
}

export default AddTenantPageWithState
