import gql from 'graphql-tag'
import { useQuery } from '@apollo/client'
import { capitalizeFirstLetter } from '../../../util/tools'

const TENANT_TYPE = gql`
    query tenantTypes {
        tenantTypes  {
            id
            slug
        }
    }
`

const useFetchAddTenantData = () => {
    const {
        data = {},
        error,
        loading,
    } = useQuery(TENANT_TYPE)

    const tenantTypes = []

    if (!loading && !error) {
        data.tenantTypes.forEach((tenantType) => {
            tenantTypes.push({
                    name: `${capitalizeFirstLetter(tenantType.slug)}`,
                    id: tenantType.id,
                })
        })
    }

    return {
        error,
        tenantTypes,
        loading
    }
}

export default useFetchAddTenantData